.TopBar{
    position: fixed;
    z-index: 2;
    left:0;
    top:0;
    width :100%;
    background:var(--md-theme-default-primary, #448aff);
    color:#FFFFFF;
    font-size:1.44em;
    font-weight: bold;
    font-family: Avenir,Helvetica,Arial,sans-serif;
    box-shadow: 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12);
    padding-left: 3%;
    padding-right: 3%;
}
.spaceTopBar{

    width :100%;
    background: #ffffff;

}
.TopBar .username{
    font-size: 0.68em;
}

@media only screen and (min-width: 100px) and (max-width: 640px) {
    .TopBarHeight{
        min-height:56px;
    }
    .spaceTopBarHeight{
        min-height:56px;
    }
}
@media only screen and (min-width: 641px) {
    .TopBarHeight{
        min-height:64px;
    }
    .spaceTopBarHeight{
        min-height:64px;
    }
}