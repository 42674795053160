.AboutLogo{
    width: 60%;
}
.AboutDiv{
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family:Avenir,Helvetica,Arial,sans-serif;
}
.AboutDiv h2{
    font-size: 1.5em;
    font-weight: bold;
    margin-top:50px;
    margin-bottom:17.3px; 
}
.AboutDiv p{
    font-size:1.0em;
    color:#000000;
    text-align: center;
}