body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.TopBar{
    position: fixed;
    z-index: 2;
    left:0;
    top:0;
    width :100%;
    background:#448aff;
    background:var(--md-theme-default-primary, #448aff);
    color:#FFFFFF;
    font-size:1.44em;
    font-weight: bold;
    font-family: Avenir,Helvetica,Arial,sans-serif;
    box-shadow: 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12);
    padding-left: 3%;
    padding-right: 3%;
}
.spaceTopBar{

    width :100%;
    background: #ffffff;

}
.TopBar .username{
    font-size: 0.68em;
}

@media only screen and (min-width: 100px) and (max-width: 640px) {
    .TopBarHeight{
        min-height:56px;
    }
    .spaceTopBarHeight{
        min-height:56px;
    }
}
@media only screen and (min-width: 641px) {
    .TopBarHeight{
        min-height:64px;
    }
    .spaceTopBarHeight{
        min-height:64px;
    }
}
.StateBarColRight{
    text-align: right;
}
.StateBarColLeft{
    text-align: left;
}
.BoldTitle{
    font-family:Avenir,Helvetica,Arial,sans-serif;
    font-weight: bold;
    margin-bottom: 0;
}
.DataTitle{
    font-family:Avenir,Helvetica,Arial,sans-serif;
    font-weight:500;
    color:#000000;
}
.Data{
    font-family:Avenir,Helvetica,Arial,sans-serif;
    font-weight:bolder;
    color:#6260e0;
}
.AboutLogo{
    width: 60%;
}
.AboutDiv{
    width:100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    font-family:Avenir,Helvetica,Arial,sans-serif;
}
.AboutDiv h2{
    font-size: 1.5em;
    font-weight: bold;
    margin-top:50px;
    margin-bottom:17.3px; 
}
.AboutDiv p{
    font-size:1.0em;
    color:#000000;
    text-align: center;
}
